import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import HomePage from "./pages/HomePage";
import SupportPage from "./pages/SupportPage";
import ProjectsPage from "./pages/ProjectsPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const getUser = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/auth/login/success`;
      const { data } = await axios.get(url, { withCredentials: true });
      console.log("Success");
      console.log("--------------------------------");
      console.log(data);
      console.log("--------------------------------");
      setUser(data.user);
    } catch (err) {
      console.error("Error fetching user:", err);
      // Redirect to login if an error occurs
      setUser(null);
      navigate("/login");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Set up Axios interceptor to handle session expiration
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // Session expired
          setUser(null);
          // Force redirect to login page without keeping the previous page in history
          window.location.replace("/login");
        }
        return Promise.reject(error);
      }
    );

    getUser();

    // Clean up interceptor on component unmount
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    // Set up Axios interceptor to handle session expiration
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          // Session expired
          setUser(null);
          navigate("/login");
        }
        return Promise.reject(error);
      }
    );

    getUser();

    // Clean up interceptor on component unmount
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <Routes>
        <Route
          path="/login"
          element={user ? <Navigate to="/" replace /> : <LoginPage />}
        />
        <Route
          path="/signup"
          element={user ? <Navigate to="/" replace /> : <SignupPage />}
        />
        <Route
          path="/"
          element={
            user ? <HomePage user={user} /> : <Navigate to="/login" replace />
          }
        >
          <Route
            path="projects"
            element={
              user ? (
                <ProjectsPage user={user} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="support"
            element={
              user ? (
                <SupportPage user={user} />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

// import "./App.css";
// import { Routes, Route, Navigate } from "react-router-dom";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import HomePage from "./pages/HomePage";
// import SupportPage from "./pages/SupportPage";
// import ProjectsPage from "./pages/ProjectsPage";
// import LoginPage from "./pages/LoginPage";
// import SignupPage from "./pages/SignupPage";

// function App() {
//   const [user, setUser] = useState(null);
//   const [isLoading, setIsLoading] = useState(true); // Loading state

//   const getUser = async () => {
//     try {
//       const url = `${process.env.REACT_APP_API_URL}/auth/login/success`;
//       const { data } = await axios.get(url, { withCredentials: true });
//       setUser(data.user);
//       console.log("User role:", data.user);
//     } catch (err) {
//       console.error("Error fetching user:", err);
//     } finally {
//       setIsLoading(false); // Set loading to false after fetching user data
//     }
//   };

//   useEffect(() => {
//     getUser();
//   }, []);

//   if (isLoading) return <div>Loading...</div>; // Show loading state while fetching user data

//   return (
//     <div>
//       {/* Optional: Uncomment the navbar if needed */}
//       {/* <AppNavbar user={user} /> */}

//       <Routes>
//         <Route
//           path="/login"
//           element={user ? <Navigate to="/" replace /> : <LoginPage />}
//         />
//         <Route
//           path="/signup"
//           element={user ? <Navigate to="/" replace /> : <SignupPage />}
//         />
//         <Route
//           path="/"
//           element={
//             user ? <HomePage user={user} /> : <Navigate to="/login" replace />
//           }
//         >
//           <Route
//             path="projects"
//             element={
//               user ? (
//                 <ProjectsPage user={user} />
//               ) : (
//                 <Navigate to="/login" replace />
//               )
//             }
//           />
//           <Route
//             path="support"
//             element={
//               user ? (
//                 <SupportPage user={user} />
//               ) : (
//                 <Navigate to="/login" replace />
//               )
//             }
//           />
//         </Route>
//       </Routes>
//     </div>
//   );
// }

// export default App;

// // import "./App.css";
// // import { Routes, Route, Navigate } from "react-router-dom";
// // import { useEffect, useState } from "react";
// // import axios from "axios";
// // import HomePage from "./pages/HomePage";
// // import SupportPage from "./pages/SupportPage";
// // import ProjectsPage from "./pages/ProjectsPage";
// // import LoginPage from "./pages/LoginPage";
// // import SignupPage from "./pages/SignupPage";

// // // import Signup from "./pages/signup";
// // // import Login from "./pages/login";
// // // import Home from "./pages/home";
// // // import AppNavbar from "./components/Navbar";
// // // import Announcements from "./pages/Announcements";
// // // import AdminPage from "./pages/Admin/AdminPage";
// // // import Dashboard from "./pages/Dashbaord";
// // // import Projects from "./components/Home/Projects";
// // // import OrdersPage from "./pages/OrdersPage";

// // function App() {
// //   const [user, setUser] = useState(null);
// //   const [isLoading, setIsLoading] = useState(true); // New loading state

// //   const getUser = async () => {
// //     try {
// //       const url = `${process.env.REACT_APP_API_URL}/auth/login/success`;
// //       const { data } = await axios.get(url, { withCredentials: true });
// //       setUser(data.user);
// //       console.log("User role:", data.user);
// //     } catch (err) {
// //       console.log("Error fetching user:", err);
// //     } finally {
// //       setIsLoading(false); // Set loading to false after fetching user data
// //     }
// //   };

// //   useEffect(() => {
// //     getUser();
// //   }, []);

// //   if (isLoading) return <div>Loading...</div>; // Show loading state while fetching user data

// //   return (
// //     <div>
// //       {/* <AppNavbar user={user} /> */}

// //       <Routes>
// //         <Route
// //           path="/login"
// //           element={user ? <Navigate to="/" /> : <LoginPage />}
// //         />
// //         <Route
// //           path="/signup"
// //           element={user ? <Navigate to="/" /> : <SignupPage />}
// //         />
// //         <Route
// //           path="/"
// //           element={user ? <HomePage user={user} /> : <Navigate to="/login" />}
// //         >
// //           <Route
// //             path="projects"
// //             element={
// //               user ? <ProjectsPage user={user} /> : <Navigate to="/login" />
// //             }
// //           />
// //           <Route
// //             path="support"
// //             element={
// //               user ? <SupportPage user={user} /> : <Navigate to="/login" />
// //             }
// //           />
// //         </Route>
// //       </Routes>
// //     </div>
// //   );
// // }

// // export default App;

// // // import "./App.css";
// // // import { Routes, Route, Navigate } from "react-router-dom";
// // // import { useEffect, useState } from "react";
// // // import axios from "axios";

// // // import Signup from "./pages/signup";
// // // import Login from "./pages/login";
// // // import Home from "./pages/home";
// // // import AppNavbar from "./components/Navbar";
// // // import Announcements from "./pages/Announcements";
// // // import AdminPage from "./pages/Admin/AdminPage";

// // // function App() {
// // //   const [user, setUser] = useState(null);

// // //   const getUser = async () => {
// // //     try {
// // //       const url = `${process.env.REACT_APP_API_URL}/auth/login/success`;
// // //       const { data } = await axios.get(url, { withCredentials: true });
// // //       setUser(data.user);
// // //       console.log(data.user.role);
// // //       // console.log(data.user._json);
// // //     } catch (err) {
// // //       console.log(err);
// // //     }
// // //   };

// // //   useEffect(() => {
// // //     getUser();
// // //   }, []);

// // //   return (
// // //     <div
// // //     // className="container"
// // //     >
// // //       <AppNavbar user={user} />
// // //       <Routes>
// // //         <Route
// // //           exact
// // //           path="/"
// // //           element={user ? <Home user={user} /> : <Navigate to="/login" />}
// // //         />
// // //         <Route
// // //           exact
// // //           path="/login"
// // //           element={user ? <Navigate to="/" /> : <Login />}
// // //         />
// // //         <Route
// // //           path="/signup"
// // //           element={user ? <Navigate to="/" /> : <Signup />}
// // //         />

// // //         <Route
// // //           path="/announcements"
// // //           element={user ? <Announcements /> : <Navigate to="/login" />}
// // //         />

// // //         <Route
// // //           path="/admin"
// // //           element={user ? <AdminPage /> : <Navigate to="/login" />}

// // //         />
// // //       </Routes>
// // //     </div>
// // //   );
// // // }

// // // export default App;
// // //   // element=<AdminPage />
// // //           // element={
// // //           //   user ? (
// // //           //     // && user.role === "ADMIN"
// // //           //     <AdminPage user={user} />
// // //           //   ) : (
// // //           //     <Navigate to="/login" />
// // //           //   )
// // //           // }
