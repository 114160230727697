import React from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Box,
  Container,
} from "@mui/material";
import SupportModel from "../components/SupportModel";

const fetchSupportTickets = async (userId) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/support/my-tickets`,
    {
      params: { user_id: userId },
    }
  );
  return response.data.data;
};

const SupportPage = ({ user }) => {
  const {
    data: tickets,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["supportTickets", user?.user_id],
    queryFn: () => fetchSupportTickets(user.user_id),
    enabled: !!user,
  });

  const columns = React.useMemo(
    () => [
      { accessorKey: "subject", header: "Subject" },
      { accessorKey: "description", header: "Description" },
      { accessorKey: "issue_type", header: "Type" },

      {
        header: "Assign to",
        accessorFn: (row) => {
          // Check for null or undefined and return a formatted string
          const firstName = row.first_name || "";
          const lastName = row.last_name || "";
          return firstName && lastName ? `${firstName} ${lastName}` : ""; // Display empty if both are empty
        },
        id: "assignTo",
        cell: (info) => <span>{info.getValue()}</span>,
      },

      { accessorKey: "email", header: "Assigned Person" },

      { accessorKey: "status", header: "Status" },
      {
        accessorKey: "created_at",
        header: "Created At",
        accessorFn: (row) => new Date(row.created_at).toLocaleDateString(),
      },
    ],
    []
  );

  const table = useReactTable({
    data: tickets || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading)
    return (
      <CircularProgress style={{ display: "block", margin: "20px auto" }} />
    );
  if (isError)
    return <Typography color="error">Error: {error.message}</Typography>;

  return (
    <div
      style={{
        padding: "20px",
        // maxWidth: "800px",
        margin: "0 auto",
      }}
    >
      {/* <Typography variant="h4" align="center" gutterBottom>
        Support Tickets
      </Typography> */}

      <Container maxWidth="xl">
        <Box
          display="flex"
          justifyContent="space-between"
          // alignItems="center"
          mb={3}
        >
          <Typography variant="h5" component="h1">
            {/* Welcome, {user?.name || "User"} */}
          </Typography>

          <SupportModel user={user} />
        </Box>
      </Container>
      {tickets && tickets.length ? (
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id} style={{ fontWeight: "bold" }}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  style={{ transition: "background-color 0.3s" }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography align="center" color="textSecondary">
          No tickets found for the given user.
        </Typography>
      )}
    </div>
  );
};

export default SupportPage;

// import React from "react";
// import axios from "axios";
// import { useQuery } from "@tanstack/react-query";
// import {
//   useReactTable,
//   getCoreRowModel,
//   flexRender,
// } from "@tanstack/react-table";

// // Fetch function to get support tickets
// const fetchSupportTickets = async (userId) => {
//   const response = await axios.get(
//     `${process.env.REACT_APP_API_URL}/support/my-tickets`,
//     {
//       params: { user_id: userId },
//     }
//   );
//   return response.data.data;
// };

// const SupportPage = ({ user }) => {
//   // Fetch support tickets using useQuery
//   const {
//     data: tickets,
//     isLoading,
//     isError,
//     error,
//   } = useQuery({
//     queryKey: ["supportTickets", user?.user_id],
//     queryFn: () => fetchSupportTickets(user.user_id),
//     enabled: !!user,
//   });

//   // Define columns for the table
//   const columns = React.useMemo(
//     () => [
//       {
//         accessorKey: "subject",
//         header: "Subject",
//       },
//       {
//         accessorKey: "status",
//         header: "Status",
//       },
//       {
//         accessorKey: "created_at",
//         header: "Created At",
//       },
//     ],
//     []
//   );

//   // Create table instance
//   const table = useReactTable({
//     data: tickets || [],
//     columns,
//     getCoreRowModel: getCoreRowModel(),
//   });

//   if (isLoading) return <div>Loading...</div>;
//   if (isError) return <div>Error: {error.message}</div>;

//   return (
//     <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
//       <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
//         Support Tickets
//       </h1>
//       {tickets && tickets.length ? (
//         <table style={{ width: "100%", borderCollapse: "collapse" }}>
//           <thead>
//             {table.getHeaderGroups().map((headerGroup) => (
//               <tr key={headerGroup.id} style={{ backgroundColor: "#f4f4f9" }}>
//                 {headerGroup.headers.map((header) => (
//                   <th
//                     key={header.id}
//                     style={{
//                       border: "1px solid #ddd",
//                       padding: "12px",
//                       fontWeight: "bold",
//                       textAlign: "left",
//                     }}
//                   >
//                     {flexRender(
//                       header.column.columnDef.header,
//                       header.getContext()
//                     )}
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody>
//             {table.getRowModel().rows.map((row, index) => (
//               <tr
//                 key={row.id}
//                 style={{
//                   backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
//                   transition: "background-color 0.3s",
//                 }}
//               >
//                 {row.getVisibleCells().map((cell) => (
//                   <td
//                     key={cell.id}
//                     style={{
//                       border: "1px solid #ddd",
//                       padding: "10px",
//                       textAlign: "left",
//                     }}
//                   >
//                     {flexRender(cell.column.columnDef.cell, cell.getContext())}
//                   </td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       ) : (
//         <p style={{ textAlign: "center", color: "#777" }}>
//           No tickets found for the given user.
//         </p>
//       )}
//     </div>
//   );
// };

// export default SupportPage;

// // import React, { useMemo } from "react";
// // import axios from "axios";
// // import { useQuery } from "@tanstack/react-query";
// // import { useTable } from "@tanstack/react-table";

// // const fetchSupportTickets = async (userId) => {
// //   // alert(`${process.env.REACT_APP_API_URL}/support/my-tickets`, userId);
// //   const response = await axios.get(
// //     `${process.env.REACT_APP_API_URL}/support/my-tickets`,
// //     {
// //       params: { user_id: userId },
// //     }
// //   );
// //   return response.data.data;
// // };

// // const SupportPage = ({ user }) => {
// //   // Using useQuery to fetch support tickets
// //   const {
// //     data: tickets,
// //     isLoading,
// //     isError,
// //     error,
// //   } = useQuery({
// //     queryKey: ["supportTickets", user?.user_id],
// //     queryFn: () => fetchSupportTickets(user.user_id),
// //     enabled: !!user, // Only run query if user is defined
// //   });

// //   const column = useMemo(() => [
// //     { Header: "Subject", accessor: "subject" },
// //     { Header: "Status", accessor: "status" },
// //     { Header: "Actions", accessor: "actions" },
// //   ]);

// //   const table = useTable({
// //     data: tickets || [],
// //     columns: column,
// //     // defaultSorted: [{ id: "subject", desc: false }],
// //   });

// //   if (isLoading) return <div>Loading...</div>;
// //   if (isError) return <div>Error: {error.message}</div>;

// //   return (
// //     <div>
// //       <h1>Support Tickets</h1>
// //       {tickets && tickets.length ? (
// //         <table>
// //           <thead>
// //             {table.getHeaderGroups().map((headerGroup) => (
// //               <tr key={headerGroup.id}>
// //                 {headerGroup.headers.map((header) => (
// //                   <th key={header.id}>{header.renderHeader()}</th>
// //                 ))}
// //               </tr>
// //             ))}
// //           </thead>
// //           <tbody>
// //             {table.getRowModel().rows.map((row) => (
// //               <tr key={row.id}>
// //                 {row.getVisibleCells().map((cell) => (
// //                   <td key={cell.id}>{cell.renderCell()}</td>
// //                 ))}
// //               </tr>
// //             ))}
// //           </tbody>
// //         </table>
// //       ) : (
// //         <p>No tickets found for the given user.</p>
// //       )}
// //     </div>
// //   );
// // };

// // export default SupportPage;
