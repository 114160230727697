import * as React from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const SupportModel = ({ user }) => {
  const [open, setOpen] = React.useState(false);
  const [issueType, setIssueType] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [location, setLocation] = React.useState("");

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    if (!user) {
      alert("Please log in to submit a ticket.");
      return; // Stop function execution if user is not logged in
    }
  }, [user]);

  const handleSubmit = async () => {
    const ticketData = {
      user_id: user.user_id || 1, // Fallback to 1 if user_id is not available
      subject,
      description,
      issue_type: issueType,
      location: location,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/support/tickets`,
        // "http://localhost:8080/support/tickets",
        ticketData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Ticket submitted successfully:", response.data);
      alert("Ticket submitted successfully!");
      handleClose(); // Close the dialog after submission
    } catch (error) {
      console.error("Error submitting ticket:", error);
      alert("Failed to submit the ticket. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        style={{ color: "white" }}
        sx={{
          marginTop: 2,
          borderRadius: 1,
          textDecoration: "none",
          textTransform: "none",
        }}
        onClick={handleClickOpen}
      >
        Submit a Ticket
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            width: "60%",
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Submit a Support Ticket
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack spacing={3}>
            <TextField
              label="Subject"
              fullWidth
              size="small"
              variant="outlined"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />

            <TextField
              label="Location"
              fullWidth
              placeholder="Location or store number"
              size="small"
              variant="outlined"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              // required
            />
            <TextField
              select
              label="Issue Type"
              size="small"
              fullWidth
              value={issueType}
              onChange={(e) => setIssueType(e.target.value)}
              variant="outlined"
              required
            >
              <MenuItem value="PRISM">Prism</MenuItem>
              <MenuItem value="Network">Network</MenuItem>

              <MenuItem value="Website">EE.com website</MenuItem>
              <MenuItem value="eComm">eComm</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Electrical">Electrical</MenuItem>
              <MenuItem value="Hardware">Hardware</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>

            <TextField
              label="Description"
              fullWidth
              multiline
              size="small"
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              placeholder="Describe the issue and any troubleshooting steps taken."
              required
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="error"
            sx={{
              marginTop: 2,
              borderRadius: 1,
              textDecoration: "none",
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="success"
            sx={{
              marginTop: 2,
              borderRadius: 1,
              textDecoration: "none",
              textTransform: "none",
            }}
          >
            Submit Ticket
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default SupportModel;
