// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query"; // Import QueryClient and Provider
// import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
// import { StyledEngineProvider } from "@mui/material/styles"; // Ensure MUI styles are injected properly
// import DashboardLayoutBasic from "./pages/Dashbaord";
// // import DashboardLayoutBasic from "./pages/Dashboard"; // Correct the import path if necessary

// // Create a QueryClient instance
// const queryClient = new QueryClient();

// // Create the root for ReactDOM
// const root = ReactDOM.createRoot(document.getElementById("root"));

// // Render the application
// root.render(
//   <React.StrictMode>
//     <QueryClientProvider client={queryClient}>
//       <StyledEngineProvider injectFirst>
//         <BrowserRouter>
//           <DashboardLayoutBasic />
//         </BrowserRouter>
//       </StyledEngineProvider>
//     </QueryClientProvider>
//   </React.StrictMode>
// );

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"; // Import QueryClient and Provider
import { BrowserRouter } from "react-router-dom"; // Import BrowserRouter
// import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { StyledEngineProvider } from "@mui/material/styles";
// import DashboardLayoutBasic from "./pages/Dashbaord";
// Create a QueryClient instance
const queryClient = new QueryClient();

// Create the root for ReactDOM
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the application
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
        {/* <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider> */}
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// // import React from "react";
// // import ReactDOM from "react-dom/client";
// // import "./index.css";
// // import App from "./App";

// // import "bootstrap/dist/css/bootstrap.min.css";

// // import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// // // Create a QueryClient instance
// // const queryClient = new QueryClient();

// // import { BrowserRouter } from "react-router-dom";

// // const root = ReactDOM.createRoot(document.getElementById("root"));
// // root.render(
// //   <React.StrictMode>
// //     <QueryClientProvider client={queryClient}>
// //       <BrowserRouter>
// //         <App />
// //       </BrowserRouter>
// //     </QueryClientProvider>
// //   </React.StrictMode>
// // );
