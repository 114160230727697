import * as React from "react";
import { Box, Button } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import CottageIcon from "@mui/icons-material/Cottage";
import { Link, Outlet, useLocation } from "react-router-dom";

import DescriptionIcon from "@mui/icons-material/Description";
import HomeComponents from "../components/HomeComponents";

const drawerWidth = 240;

export default function HomePage({ user }) {
  const location = useLocation();

  // Check if the current path is the home route
  const isHomeRoute = location.pathname === "/";

  // Define the list items for the drawer menu
  const menuItems = [
    { icon: <CottageIcon />, text: "Home", path: "/" },
    { icon: <DescriptionIcon />, text: "My Case", path: "/support" },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          bgcolor: "#1976d2",
          color: "white",
        }}
      >
        <Toolbar sx={{ bgcolor: "#012B40FF" }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, color: "#EFEEEECD" }}
            noWrap
            component="p"
          >
            Electronic Express
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant="contained"
            sx={{
              color: "white",
              borderColor: "#E91E63",
              backgroundColor: "#E91E63",
              "&:hover": {
                backgroundColor: "#C90B4AFF",
                color: "white",
              },
              textTransform: "none",
              ml: 2,
            }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            bgcolor: "#eaeff1",
            borderRight: "0px solid #ccc",
            boxShadow: 1,
            // alignItems: "center",
            scale: 1,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar sx={{ bgcolor: "#012B40FF" }}>
          <Box flex={1}>
            <Typography
              variant="h4"
              sx={{
                color: "#E0E0E0FF",
                fontFamily: "'Roboto Slab', serif", // Example: change to your desired font
                fontWeight: 700,
                fontSize: 30,
                letterSpacing: 6,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              EE
            </Typography>
          </Box>

          {/* <img
            src={
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTimk56VDztQDQRZMv-IjD-12yDlZ_wvuujsA&s"
              // "https://ctfassets.zip.co/l6e5nwwfjxup/131IBDc8uKoZzok2vZQetq/59dab9f86a6bf550ce5a77f41da6ea94/unnamed.png"
            }
            alt="Logo"
            style={{ width: "60px" }}
          /> */}
        </Toolbar>
        <Divider />
        <List>
          {menuItems.map(({ icon, text, path }) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                component={Link}
                to={path}
                sx={{
                  px: 2,
                  py: 1,
                  borderRadius: 1,
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                  "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor: "#A17317FF",
                  },
                }}
              >
                <ListItemIcon sx={{ color: "#4C4B4BFF", minWidth: "40px" }}>
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{
                    fontWeight: 600,
                    color: "#282727FF",
                    fontSize: "0.9rem",
                  }}
                />
              </ListItemButton>

              {/* <ListItemButton component={Link} to={path}>
                <ListItemIcon sx={{ color: "#B1B9BBDC" }}>{icon}</ListItemIcon>
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{ fontWeight: 500 }}
                />
              </ListItemButton> */}
              <Divider />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "#eaeff1",
          p: 3,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        {/* Render ProjectsPage if on the home route, otherwise show Outlet for nested routes */}
        {isHomeRoute ? <HomeComponents user={user} /> : <Outlet />}
      </Box>
    </Box>
  );
}

// import * as React from "react";
// import { Box, Button } from "@mui/material";
// import Drawer from "@mui/material/Drawer";
// import CssBaseline from "@mui/material/CssBaseline";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
// import List from "@mui/material/List";
// import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";

// import CottageIcon from "@mui/icons-material/Cottage";
// import { Link, Outlet, useLocation } from "react-router-dom";

// import Projects from "../components/Projects";

// import DescriptionIcon from "@mui/icons-material/Description";
// import HomeComponents from "../components/HomeComponents";

// const drawerWidth = 240;

// export default function HomePage({ user }) {
//   const location = useLocation();

//   // Check if the current path is the home route
//   const isHomeRoute = location.pathname === "/";

//   return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />
//       <AppBar
//         position="fixed"
//         sx={{
//           width: `calc(100% - ${drawerWidth}px)`,
//           ml: `${drawerWidth}px`,
//           bgcolor: "#1976d2",
//           color: "white",
//         }}
//       >
//         <Toolbar sx={{ bgcolor: "#012B40FF" }}>
//           <Typography variant="h6" noWrap component="div">
//             Electronic Express
//           </Typography>
//           <Box sx={{ flexGrow: 1 }} />
//           <Button
//             variant="contained"
//             sx={{
//               color: "white",
//               borderColor: "#E91E63",
//               backgroundColor: "#E91E63",
//               "&:hover": {
//                 backgroundColor: "#C90B4AFF",
//                 color: "white",
//               },
//               textTransform: "none",
//               ml: 2,
//             }}
//             // onClick={logout}
//           >
//             Logout
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Drawer
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           "& .MuiDrawer-paper": {
//             width: drawerWidth,
//             boxSizing: "border-box",
//             bgcolor: "#f4f4f4",
//             borderRight: "1px solid #ccc",
//           },
//         }}
//         variant="permanent"
//         anchor="left"
//       >
//         <Toolbar>
//           <img
//             src={
//               "https://ctfassets.zip.co/l6e5nwwfjxup/131IBDc8uKoZzok2vZQetq/59dab9f86a6bf550ce5a77f41da6ea94/unnamed.png"
//             }
//             alt="Logo"
//             style={{ width: "60px" }}
//           />
//         </Toolbar>
//         <Divider />
//         <List>
//           {[
//             { icon: <CottageIcon />, text: "Home", path: "/" },
//             { icon: <DescriptionIcon />, text: "My Case", path: "/support" },
//           ].map(({ icon, text, path }) => (
//             <ListItem key={text} disablePadding>
//               <ListItemButton component={Link} to={path}>
//                 <ListItemIcon>{icon}</ListItemIcon>
//                 <ListItemText
//                   primary={text}
//                   primaryTypographyProps={{ fontWeight: 500 }}
//                 />
//               </ListItemButton>
//               <Divider />
//             </ListItem>
//           ))}
//         </List>
//         <Divider />
//       </Drawer>
//       <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           bgcolor: "#eaeff1",
//           p: 3,
//           height: "100vh",
//           overflow: "auto",
//         }}
//       >
//         <Toolbar />
//         {/* Render ProjectsPage if on the home route, otherwise show Outlet for nested routes */}
//         {isHomeRoute ? <HomeComponents user={user} /> : <Outlet />}
//       </Box>
//     </Box>
//   );
// }
