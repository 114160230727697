import { Link } from "react-router-dom";
import React, { useState } from "react";

const SignupPage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const googleAuth = () => {
    setIsLoading(true);
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/google/callback`,
      "_self"
    );
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Sign Up</h2>

      <button
        style={isLoading ? styles.loadingButton : styles.googleButton}
        onClick={googleAuth}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Sign Up with Google"}
      </button>

      <p style={styles.loginText}>
        Already have an account?{" "}
        <Link to="/login" style={styles.loginLink}>
          Log In
        </Link>
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#f7f7f7",
    padding: "20px",
  },
  header: {
    fontSize: "24px",
    marginBottom: "20px",
  },
  googleButton: {
    backgroundColor: "#4285F4",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
    marginBottom: "15px",
  },
  loadingButton: {
    backgroundColor: "#4285F4",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "not-allowed",
    fontSize: "16px",
    marginBottom: "15px",
    opacity: 0.7,
  },
  loginText: {
    fontSize: "14px",
    color: "#555",
  },
  loginLink: {
    color: "#4285F4",
    textDecoration: "none",
    fontWeight: "bold",
  },
};

export default SignupPage;
