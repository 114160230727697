import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Projects from "./Projects";
import Announcements from "./Announcements";
const HomeComponents = ({ user }) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {/* First Column */}
        <Grid item xs={12} lg={8}>
          <Projects user={user} />
          {/* <Typography variant="h6">Column 1</Typography>
          <Typography>
            This is the content for the first column. It will be displayed on
            large screens and hidden on small screens.
          </Typography> */}
        </Grid>

        {/* Second Column */}
        <Grid item xs={12} lg={4} sx={{ display: { xs: "none", lg: "block" } }}>
          <Announcements user={user} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeComponents;
