import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  Box,
} from "@mui/material";

const fetchProjects = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/projects`);
  return response.data;
};

const Projects = ({ user }) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["projects"],
    queryFn: fetchProjects,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h5" component="h1">
          Welcome, {user?.name || "User"}
        </Typography>

        {/* <SupportModel user={user} /> */}
      </Box>

      <Box sx={{ padding: 2 }}>
        <Box>
          <Grid container spacing={2}>
            {data.data.map((project) => (
              <Grid item key={project.project_id} xs={12} sm={6} md={4}>
                <Card sx={{ maxHeight: 165, minHeight: 160, borderRadius: 2 }}>
                  <CardContent
                    sx={{
                      position: "relative",
                      minHeight: 200, // Adjust the height as needed
                    }}
                  >
                    <Typography variant="h6" component="div">
                      {project.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {project.description}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      href={project.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        position: "absolute",
                        bottom: 46, // Spacing from the bottom
                        right: 16, // Spacing from the left
                        borderRadius: 1,
                        textDecoration: "none",
                        textTransform: "none",
                      }}
                    >
                      Visit site
                    </Button>
                  </CardContent>
                  {/* <CardContent>
                <Typography variant="h5" component="div">
                  {project.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {project.description}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  
                  size="small"
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    marginTop: 2,
                    
                    borderRadius: 1,
                    textDecoration: "none",
                    textTransform: "none",
                  }}
                >
                  Visit site
                </Button>
              </CardContent> */}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

export default Projects;

// import React from "react";
// import { useQuery } from "@tanstack/react-query";
// import axios from "axios";

// const fetchProjects = async () => {
//   const response = await axios.get("http://localhost:8080/projects");
//   return response.data;
// };

// const Projects = () => {
//   const { data, error, isLoading } = useQuery({
//     queryKey: ["projects"], // Use queryKey instead of an array
//     queryFn: fetchProjects, // Use queryFn for the fetch function
//   });

//   if (isLoading) return <div>Loading...</div>;
//   if (error) return <div>An error occurred: {error.message}</div>;

//   return (
//     <div>
//       <h1>Projects</h1>
//       <ul>
//         {data.data.map((project) => (
//           <li key={project.project_id}>
//             <h2>{project.title}</h2>
//             {project.logo_url && (
//               <img src={project.logo_url} alt={project.title} />
//             )}
//             <p>{project.description}</p>
//             <a href={project.url} target="_blank" rel="noopener noreferrer">
//               Visit Project
//             </a>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default Projects;
