import React from "react";
import {
  Box,
  Typography,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  Stack,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const Announcements = () => {
  const fetchAnnouncements = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/announcements/`
      // "http://localhost:8080/announcements"
    );
    return response.data.announcements;
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ["announcements"],
    queryFn: fetchAnnouncements,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <Box mt={10}>
      <Paper
        sx={{
          minHeight: "600px",
          bgcolor: "#D7E8E5FF",
        }}
      >
        <Box px={1}>
          <Typography
            variant="h5"
            sx={{ py: 1, fontWeight: 400, fontSize: 18, spacing: 5 }}
            // color="secondary"
            color="#021714FF"
          >
            Announcements
          </Typography>

          <Box
            sx={{
              maxHeight: 550, // Set max height for scroll area
              overflowY: "auto", // Enable vertical scrolling
            }}
          >
            <Stack spacing={1}>
              {data.map((announcement) => (
                <Paper
                  key={announcement.announcement_id}
                  sx={{
                    p: 1,
                    bgcolor: "#FFFFFF",
                    minHeight: 80,
                  }}
                >
                  <ListItem disablePadding>
                    <ListItemText
                      primary={announcement.title}
                      secondary={
                        <Box>
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                          >
                            {announcement.content}
                          </Typography>
                          <Typography
                            component="span"
                            variant="caption"
                            color="text.secondary"
                            display="block"
                          >
                            Created at:{" "}
                            {new Date(announcement.created_at).toLocaleString()}
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                  <Divider />
                </Paper>
              ))}
            </Stack>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default Announcements;

// import React from "react";
// import {
//   Box,
//   Typography,
//   ListItem,
//   ListItemText,
//   Divider,
//   Paper,
//   Stack,
// } from "@mui/material";
// import { useQuery } from "@tanstack/react-query";
// import axios from "axios";

// const Announcements = () => {
//   const fetchAnnouncements = async () => {
//     const response = await axios.get("http://localhost:8080/announcements");
//     return response.data.announcements;
//   };

//   const { data, error, isLoading } = useQuery({
//     queryKey: ["announcements"],
//     queryFn: fetchAnnouncements,
//   });

//   if (isLoading) return <div>Loading...</div>;
//   if (error) return <div>An error occurred: {error.message}</div>;

//   return (
//     <Box mt={10}>
//       <Paper
//         sx={{
//           minHeight: "600px",
//           bgcolor: "#DFDFDF",
//         }}
//       >
//         <Box px={1}>
//           <Typography variant="h5" sx={{ py: 1 }}>
//             Announcements
//           </Typography>

//           <Box
//             sx={{
//               maxHeight: 450, // Set max height for scroll area
//               overflowY: "auto", // Enable vertical scrolling
//             }}
//           >
//             <Stack spacing={1}>
//               {data.map((announcement) => (
//                 <Paper
//                   key={announcement.announcement_id}
//                   sx={{
//                     p: 1,
//                     bgcolor: "#FFFFFF",
//                     minHeight: 80,
//                   }}
//                 >
//                   <ListItem disablePadding>
//                     <ListItemText
//                       primary={announcement.title}
//                       secondary={
//                         <Typography
//                           component="span"
//                           variant="body2"
//                           color="text.secondary"
//                         >
//                           {announcement.content}
//                         </Typography>

//                             <Typography
//                               component="span"
//                               variant="caption"
//                               color="text.secondary"
//                             >
//                               Created at:{" "}
//                               {new Date(
//                                 announcement.created_at
//                               ).toLocaleString()}
//                             </Typography>
//                       }

//                     />

//                   </ListItem>
//                   <Divider />
//                 </Paper>
//               ))}
//             </Stack>
//           </Box>
//         </Box>
//       </Paper>
//     </Box>
//   );
// };

// export default Announcements;

// // import React from "react";

// // import {
// //   Box,
// //   Container,
// //   Typography,
// //   List,
// //   ListItem,
// //   ListItemText,
// //   Divider,
// //   Paper,
// //   Stack,
// // } from "@mui/material";
// // import { useQuery } from "@tanstack/react-query";
// // import axios from "axios";

// // const Announcements = ({ user }) => {
// //   const fetchAnnouncements = async () => {
// //     const response = await axios.get("http://localhost:8080/announcements");
// //     return response.data.announcements; // Ensure to return only the announcements array
// //   };

// //   const { data, error, isLoading } = useQuery({
// //     queryKey: ["announcements"],
// //     queryFn: fetchAnnouncements,
// //   });

// //   if (isLoading) return <div>Loading...</div>;
// //   if (error) return <div>An error occurred: {error.message}</div>;
// //   return (
// //     <Box mt={10}>
// //       <Paper
// //         sx={{
// //           minHeight: "400px",
// //           bgcolor: "#DFDFDFFF",
// //         }}
// //       >
// //         <Box px={0.75}>
// //           <Typography variant="h5" color="initial" sx={{ py: 1 }}>
// //             Announcements
// //           </Typography>

// //           <Box>
// //             <Stack>
// //               {data.map((announcement) => (
// //                 <Paper
// //                   sx={{
// //                     mt: 0.1,
// //                     // bgcolor: "#FFFFFFFF",
// //                     // borderRadius: 2,
// //                     minHeight: 80,
// //                   }}
// //                 >
// //                   <React.Fragment key={announcement.announcement_id}>
// //                     <ListItem>
// //                       <ListItemText
// //                         primary={announcement.title}
// //                         secondary={
// //                           <React.Fragment>
//                             // <Typography
//                             //   component="span"
//                             //   variant="body2"
//                             //   color="text.secondary"
//                             // >
//                             //   {announcement.content}
//                             // </Typography>
//                             // <br />
//                             // <Typography
//                             //   component="span"
//                             //   variant="caption"
//                             //   color="text.secondary"
//                             // >
//                             //   Created at:{" "}
//                             //   {new Date(
//                             //     announcement.created_at
//                             //   ).toLocaleString()}
//                             // </Typography>
// //                           </React.Fragment>
// //                         }
// //                       />
// //                     </ListItem>
// //                     <Divider />
// //                   </React.Fragment>
// //                 </Paper>
// //               ))}
// //             </Stack>
// //           </Box>
// //         </Box>
// //       </Paper>
// //     </Box>
// //   );
// // };

// // export default Announcements;
